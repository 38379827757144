import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const router = new Router({
    mode: 'hash',
    base: process.env.BABEL_ENV,
    routes: [
        {
            path: '/',
            redirect: '/cashOut',
            component: () => import('@/views/index/index'),
            children: [
                {
                    path: '/index',
                    component: () => import('@/views/index/index')
                },
                {
                    path: '/cashOut',
                    component: () => import('@/views/cashOut/cashOut')
                },
                {
                    path: '/drawing',
                    component: () => import('@/views/drawing/drawing')
                }
            ]
        },
        {
            path: '/error',
            component: () => import('@/views/error/error')
        }
    ]
});

router.beforeEach((to, from, next) => {
    if (to.path === '/cashOut' || to.path === '/drawing') {
        var ua = window.navigator.userAgent.toLowerCase();
        if (ua.indexOf('micromessenger') !== -1) {
            next();
        } else {
            next('/error');
        }
        return;
    }
    next();
});

export default router;
