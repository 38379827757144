<template>
    <div id="app" class="viewport">
        <router-view />
    </div>
</template>

<script>
export default {
    name: 'App'
};
</script>
