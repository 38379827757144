import axios from 'axios';
import Vue from 'vue';
import { Toast } from 'vant';

const baseUrl = process.env.NODE_ENV === 'development' ? 'http://8zwa2w.natappfree.cc' : 'https://api.fzputi.com/weixin';

const ajax = axios.create({
    baseURL: baseUrl,
    timeout: 0
});

ajax.interceptors.request.use(config => {
    !config.noLoad && Toast.loading({
        duration: 0,
        message: '加载中...',
        forbidClick: true
    });
    return config;
});

ajax.interceptors.response.use(res => {
    Toast.clear();
    const { code, data, msg } = res.data;
    switch (code) {
        case 200:
            return data;
        default:
            Toast(msg || '网络错误');
            return Promise.reject(msg);
    }
}, () => {
    Toast('网络错误');
    return Promise.reject('网络错误');
});

Vue.prototype.$ajax = ajax;

