import Vue from 'vue';
import App from './App.vue';
import router from '@/router';
import Vant from 'vant';
import 'vant/lib/index.css';
import '@/ajax/index';
import('@/assets/scss/common.scss');
import('@/assets/scss/ignoreRem/rem.scss');

Vue.use(Vant);
Vue.config.productionTip = false;

new Vue({
    router,
    render: h => h(App)
}).$mount('#app');
